import { SunsApi, SunsApiError } from "@suns/api";
import { getAuthToken } from "./auth";

export const {
  getGame,
  getTeam,
  getTeams,
  getRollingSchedule,
  getSchedule,
  apolloApi,
} = new SunsApi({
  apiKeys: {
    // TODO: Hide these behind our Apollo API.
    // These api endpoints don't offer sensitive data so it's not critical.
    querytool: import.meta.env.VITE_NBA_API_QUERY_TOOL_KEY,
    scores: import.meta.env.VITE_NBA_API_SCORES_KEY,
    stats: import.meta.env.VITE_NBA_API_STATS_KEY,
    schedule: import.meta.env.VITE_NBA_API_SCHEDULE_KEY,
  },
  apolloUrl: import.meta.env.VITE_API_URL,
  nbaUrl: `${import.meta.env.VITE_API_URL}/nba-api`,
  getToken: async () => {
    const token = await getAuthToken();

    if (!token) {
      throw new SunsApiError("Token authentication failed.", {
        authError: true,
      });
    }

    return token;
  },
});
