import { QueryParams } from "./types/inputs";
import { transformParamsForRequest } from "./params-transformer";
import { ApiKeys, NbaApi } from "./nba-api";
import { ApolloApi } from "./apollo-api";
import {
  BoxScoreDataModel,
  NextGenBoxScoreTeam,
  TeamStatsStat,
  seasonType as SeasonType,
  TeamStatsDataModel as TeamStatsDataModelBase,
  TeamStatsTeam as TeamStatsTeamBase,
} from "../generated-client/stats";
import {
  GameLeagueDataModel,
  GameLineupsDataModel,
  GamePlayerDataModel,
  GameTeamDataModel,
  SeasonLeagueDataModel,
  SeasonLineupsDataModel,
  SeasonPlayerDataModel,
  SeasonTeamDataModel,
} from "../generated-client/querytool";
import {
  ApiResponse,
  QueryLeaguesResponse,
  QueryLineupsResponse,
  QueryPlayersResponse,
  QueryTeamsResponse,
} from "./types/responses";
import { ScoreboardDataModel } from "../generated-client/scores";
import {
  ScheduleFullDataModel,
  ScheduleRollingDataModel,
} from "../generated-client/schedule";
import dayjs from "dayjs";
import { LeagueId } from "./params";
import { SunsApiError } from "./error";

interface GetGameParams<MeasureType = "BoxScore" | "AdvancedBoxScore"> {
  gameId: string;
  measureType: MeasureType;
}

interface GetTeamParams {
  teamId: number;
  leagueId: string;
  season?: string;
  seasonType?: `${SeasonType}`;
  perMode?: "Totals" | "PerGame";
}

interface GetScheduleParams {
  teamId: number;
  season: string;
  leagueId: LeagueId;
}

interface GetRollingScheduleParams {
  date: string;
  leagueId: string;
}
interface NextGenAdvancedBoxScoreTeam
  extends Omit<NextGenBoxScoreTeam, "statistics"> {
  statistics?: TeamStatsStat;
}

interface AdvancedBoxScoreDataModel
  extends Omit<BoxScoreDataModel, "homeTeam" | "awayTeam"> {
  homeTeam?: NextGenAdvancedBoxScoreTeam;
  awayTeam?: NextGenAdvancedBoxScoreTeam;
}

// There's some missing data points in the OpenAPI schemas
interface TeamStatsTeam extends TeamStatsTeamBase {
  stats?: TeamStatsStat & {
    oppEfgPct?: number;
    oppTovPct?: number;
  };
}

interface TeamStatsDataModel extends Omit<TeamStatsDataModelBase, "teams"> {
  teams?: TeamStatsTeam[] | null;
}

export class SunsApi {
  nba: NbaApi;
  apolloApi: ApolloApi;

  constructor({
    apiKeys,
    nbaUrl,
    apolloUrl,
    getToken,
  }: {
    apiKeys: ApiKeys;
    getToken: () => Promise<string>;
    nbaUrl?: string;
    apolloUrl?: string;
  }) {
    this.nba = new NbaApi({ url: nbaUrl, apiKeys });
    this.apolloApi = new ApolloApi({ url: apolloUrl, getToken });

    this.queryPlayers = this.queryPlayers.bind(this);
    this.queryTeams = this.queryTeams.bind(this);
    this.queryLeagues = this.queryLeagues.bind(this);
    this.queryLineups = this.queryLineups.bind(this);
    this.getGame = this.getGame.bind(this);
    this.getTeam = this.getTeam.bind(this);
    this.getTeams = this.getTeams.bind(this);
    this.getSchedule = this.getSchedule.bind(this);
    this.getRollingSchedule = this.getRollingSchedule.bind(this);
  }

  async queryPlayers(
    params: QueryParams<"game">
  ): QueryPlayersResponse<GamePlayerDataModel>;
  async queryPlayers(
    params: QueryParams<"season">
  ): QueryPlayersResponse<SeasonPlayerDataModel>;
  async queryPlayers(params: QueryParams): QueryPlayersResponse {
    const { scope } = params;
    const response = await this.nba.get({
      url: `/querytool/${scope}/player`,
      params: transformParamsForRequest(params),
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting players.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async queryTeams(
    params: QueryParams<"game">
  ): QueryTeamsResponse<GameTeamDataModel>;
  async queryTeams(
    params: QueryParams<"season">
  ): QueryTeamsResponse<SeasonTeamDataModel>;
  async queryTeams(params: QueryParams): QueryTeamsResponse {
    const { scope } = params;
    const response = await this.nba.get({
      url: `/querytool/${scope}/team`,
      params: transformParamsForRequest(params),
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting teams.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async queryLeagues(
    params: QueryParams<"game">
  ): QueryLeaguesResponse<GameLeagueDataModel>;
  async queryLeagues(
    params: QueryParams<"season">
  ): QueryLeaguesResponse<SeasonLeagueDataModel>;
  async queryLeagues(params: QueryParams): QueryLeaguesResponse {
    const { scope } = params;
    const response = await this.nba.get({
      url: `/querytool/${scope}/league`,
      params: transformParamsForRequest(params),
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting leauges.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async queryLineups(
    params: QueryParams<"game">
  ): QueryLineupsResponse<GameLineupsDataModel>;
  async queryLineups(
    params: QueryParams<"season">
  ): QueryLineupsResponse<SeasonLineupsDataModel>;
  async queryLineups(params: QueryParams): QueryLineupsResponse {
    const { scope } = params;
    const response = await this.nba.get({
      url: `/querytool/${scope}/lineups`,
      params: transformParamsForRequest(params),
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting lineups.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getTeam(params: GetTeamParams) {
    const {
      teamId,
      leagueId,
      season = "2023-24",
      seasonType = "Regular Season",
      perMode = "Totals",
    } = params;

    const response = await this.nba.get<TeamStatsDataModel>({
      url: "/stats/team",
      params: { leagueId, teamId, season, seasonType, perMode },
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting the team.", {
        cause: response.error,
      });
    }

    const team = response.data?.teams?.[0];

    if (!team) {
      throw Error("There was an error getting the team.");
    }

    return team;
  }

  async getTeams(params: Omit<GetTeamParams, "teamId">) {
    const {
      leagueId,
      season = "2024-25",
      seasonType = "Regular Season",
      perMode = "Totals",
    } = params;

    const response = await this.nba.get<TeamStatsDataModel>({
      url: "/stats/team",
      params: { leagueId, season, seasonType, perMode },
    });

    if (response.error) {
      throw new SunsApiError("There was an error getting teams.", {
        cause: response.error,
      });
    }

    const teams = response.data?.teams;

    if (!teams) {
      throw Error("Error when querying teams.");
    }

    return teams;
  }

  async getGame(
    params: Omit<GetGameParams, "measureType">
  ): Promise<
    NonNullable<NonNullable<ScoreboardDataModel["scoreboard"]>["games"]>[number]
  >;
  async getGame(params: GetGameParams<"BoxScore">): Promise<BoxScoreDataModel>;
  async getGame(
    params: GetGameParams<"AdvancedBoxScore">
  ): Promise<AdvancedBoxScoreDataModel>;
  async getGame(params: GetGameParams): Promise<object> {
    const { gameId, measureType } = params;
    let response: Awaited<ApiResponse>;

    if (measureType == "BoxScore" || measureType == "AdvancedBoxScore") {
      const boxScoreMeasureType =
        measureType == "AdvancedBoxScore" ? "Advanced" : undefined;
      response = await this.nba.get({
        url: "/stats/boxscore",
        params: { gameId, measureType: boxScoreMeasureType },
      });
    } else {
      const scoreboardResponse = await this.nba.get<ScoreboardDataModel>({
        url: "/scores/scoreboard/games",
        params: { leagueId: leagueIdFromGameId(gameId), gameId },
      });
      if (scoreboardResponse.status !== 200) {
        response = scoreboardResponse;
      } else {
        response = {
          ...scoreboardResponse,
          data: {
            ...scoreboardResponse.data?.scoreboard?.games?.[0],
          },
        };
      }
    }

    if (response.error) {
      throw new SunsApiError("There was an error getting the game.", {
        cause: response.error,
      });
    }

    return response.data;
  }

  async getRollingSchedule(params: GetRollingScheduleParams) {
    const { date, leagueId } = params;
    const formateDate = dayjs(date).format("YYYY-MM-DD");
    const response = await this.nba.get<ScheduleRollingDataModel>({
      url: "/schedule/rolling",
      params: { gameDate: formateDate, leagueId },
    });
    if (response.error) {
      throw new SunsApiError("There was an error getting the schedule.", {
        cause: response.error,
      });
    }
    const schedule = response.data?.rollingSchedule;
    if (!schedule) {
      throw Error("Error when querying schedule.");
    }
    return schedule;
  }

  async getSchedule(params: GetScheduleParams) {
    const response = await this.nba.get<ScheduleFullDataModel>({
      url: "/schedule/full",
      params,
    });

    if (response.error) {
      throw response.error;
    }

    const schedule = response.data?.leagueSchedule;

    if (!schedule) {
      throw Error("Error when querying schedule.");
    }

    return schedule;
  }
}

function leagueIdFromGameId(gameId: string) {
  return gameId.substring(0, 2);
}
