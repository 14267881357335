import { Badge, Button, Flex, Text, TextEditor } from "@suns/design-system";
import { IntelRow } from "@suns/api/generated-client/apollo";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export function IntelListItem({
  intel,
  className,
}: {
  intel: IntelRow;
  className?: string;
}) {
  const player = intel.players?.[0];
  return (
    <Flex direction="down" gap="sm" className={className}>
      <Flex gap="sm">
        {intel.tags?.map((key) => {
          return (
            <Badge key={`tag-${key}`} variant="secondary">
              {key}
            </Badge>
          );
        })}
      </Flex>
      <Text size="2xl" heading>
        {player?.firstName} {player?.lastName}
      </Text>
      <TextEditor defaultValue={intel.notes} readonly />
      <Flex gap="sm">
        <Button size="sm" variant="outline">
          <Link to={`/reports/intel/view/${player?.id}/${intel.id}`}>View</Link>
        </Button>
        <Button size="sm" variant="outline">
          <Link to={`/reports/intel/edit/${player?.id}/${intel.id}`}>Edit</Link>
        </Button>
      </Flex>
      <Flex direction="right" gap="xs">
        <Text muted heading>
          {dayjs(intel.createdAt).format("MMMM Do YYYY")}
        </Text>
        <Text muted>by</Text>
        <Text muted heading>
          {intel.authorName}
        </Text>
      </Flex>
    </Flex>
  );
}
