/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type IntelQueryParams = {
    offset?: number;
    limit?: number;
    id?: number;
    playerId?: number;
    teamId?: number;
    level?: IntelQueryParams.level;
    status?: IntelQueryParams.status;
    source?: string;
    trustworthiness?: number;
    authorUsername?: string;
};
export namespace IntelQueryParams {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
}

