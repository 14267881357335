import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Card,
  Flex,
  Select,
  SelectOption,
  SkeletonText,
  Tabs,
  Text,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import {
  PlayerRow,
  ReportResponseItem,
  TeamRow,
} from "@suns/api/generated-client/apollo";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
  GameHero,
  GameHeroSkeleton,
  NavigateLogin,
} from "@/components";
import { useAccount, useAsync } from "@/shared/hooks";
import { apolloApi } from "@/shared/api";
import { reportLoader } from "../report-loader";
import { reportFormDataToUpsertParams } from "../report-utils";
import {
  GameReportForm,
  GameReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { notify } from "@/components/bugsnag";
import { PlayerReportItem } from "./components/PlayerReportItem";
import { PlayerList } from "./components/PlayerList";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "@/shared/utils/toast-messages";
import { SunsApiError } from "@suns/api";

type TeamTabValues = "home" | "away";

const sortByMetadataAndName = (player1: PlayerRow, player2: PlayerRow) => {
  if (player1.metadata?.target && !player2.metadata?.target) return -1;
  if (!player1.metadata?.target && player2.metadata?.target) return 1;

  const lastNameComparison = player1.lastName.localeCompare(player2.lastName);
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  }
  return player1.firstName.localeCompare(player2.firstName);
};

export function CreateGameReport() {
  const { gameId } = useParams();
  const [navigateLogin, setNavigateLogin] = useState(false);
  const account = useAccount();
  const [searchParams] = useSearchParams();
  const playerId = searchParams.get("playerId");

  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const [reports, setReports] = useState<Record<string, ReportResponseItem>>(
    {}
  );
  const [activeTab, setActiveTab] = useState<TeamTabValues>("home");
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerRow | null>(null);

  const {
    loading,
    response,
    error: responseError,
  } = useAsync(reportLoader, {
    gameVendorId: gameId ?? "",
    authorUsername: account.info?.username ?? "",
  });

  useEffect(() => {
    if (response && response.reports) {
      setReports(response.reports);

      const firstPlayer =
        response.homeTeam?.currentPlayers.sort(sortByMetadataAndName)[0] ??
        null;
      setSelectedPlayer(firstPlayer);
    }
  }, [response]);

  useEffect(() => {
    if (response && response.reports && playerId && selectedPlayer === null) {
      let initialPlayer = response.homeTeam?.currentPlayers.find(
        (player) => player.id.toString() === playerId
      );
      if (!initialPlayer) {
        initialPlayer = response.awayTeam?.currentPlayers.find(
          (player) => player.id.toString() === playerId
        );
      }

      if (initialPlayer) {
        if (response.homeTeam?.id === initialPlayer.currentTeamId) {
          setActiveTab("home");
        } else {
          setActiveTab("away");
        }

        setSelectedPlayer(initialPlayer);
      }
    }
  }, [response, playerId, selectedPlayer]);

  if (navigateLogin) {
    return <NavigateLogin />;
  }

  if (loading || !selectedPlayer) {
    return <CreateGameReportLoading />;
  }

  if (responseError) {
    throw new SunsApiError("Error loading the game.", { cause: responseError });
  }

  if (!gameId) {
    throw Error("Error loading the game.");
  }

  if (!account.info) {
    throw Error("Error loading account info.");
  }

  const { game, homeTeam, awayTeam } = response;

  if (!homeTeam || !awayTeam || !game) {
    throw Error("There was an error loading the rosters.");
  }

  function handleTabChange(newTab: string) {
    if (newTab !== activeTab) {
      setActiveTab(newTab as TeamTabValues);
      if (newTab === "home") {
        handlePlayerSelected(homeTeam?.currentPlayers[0] ?? null);
      } else {
        handlePlayerSelected(awayTeam?.currentPlayers[0] ?? null);
      }
    }
  }

  function handlePlayerSelected(player: PlayerRow | null) {
    if (player) {
      setSelectedPlayer(player);
    }
  }

  async function handleSubmit(
    report: GameReportFormSchema,
    publish: boolean = false
  ) {
    if (publish) {
      setIsPublishing(true);
    } else {
      setIsSaving(true);
    }

    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      const existingReport = report.playerId
        ? reports[report.playerId]
        : undefined;
      if (existingReport != undefined) {
        upsertParams.id = existingReport.id;
      }

      const res = await apolloApi.saveReport(upsertParams);
      setReports({
        ...reports,
        [res.report.playerId]: res.report,
      });

      if (publish) {
        toastSuccess("Report Published");
      } else {
        toastInfo("Report Saved");
      }
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toastError("Please log back in to save.");
        setNavigateLogin(true);
      }
      notify(new Error("Error saving report.", { cause: e }));
      toastError(
        `Unable to ${publish ? "publish" : "save"} report. Please try again.`
      );
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
    }
  }

  const activeReport = reports[selectedPlayer.id];
  const activeTeam = activeTab === "home" ? homeTeam : awayTeam;

  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Create Report</Text>
      </Flex>
      <Card className="mb-4 overflow-hidden">
        <GameHero
          leagueId={game.leagueId}
          gameStatus={game.gameStatus || 1}
          gameTimeUTC={game.gameTimeUTC}
          gameClock={game.gameClock}
          period={game.period}
          homeTeam={{
            id: game.homeTeam?.id,
            image: game.homeTeam?.image,
            tricode: game.homeTeam?.tricode,
            score: game.homeTeam!.score,
          }}
          awayTeam={{
            id: game.awayTeam?.id,
            image: game.awayTeam?.image,
            tricode: game.awayTeam?.tricode,
            score: game.awayTeam?.score,
          }}
        />
      </Card>
      <Card className="mb-4 md:hidden">
        <TeamTabs
          value={activeTab}
          handleTabChange={handleTabChange}
          handlePlayerSelected={handlePlayerSelected}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          activePlayer={selectedPlayer}
          reports={reports}
        />
      </Card>
      <Card className="md:grid md:grid-cols-5 md:space-x-8">
        <Flex className="hidden md:flex">
          <TeamTabs
            value={activeTab}
            handleTabChange={handleTabChange}
            handlePlayerSelected={handlePlayerSelected}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            activePlayer={selectedPlayer}
            reports={reports}
          />
        </Flex>
        <Flex className="col-span-4" direction="down" gap="lg">
          <PlayerHeader
            {...selectedPlayer}
            playerImage={selectedPlayer.image}
            teamNbaId={activeTeam.nbaId}
            teamName={activeTeam.name}
            teamImage={activeTeam.image}
            leagueId={game.leagueId}
          />
          <GameReportForm
            key={selectedPlayer.id}
            onSubmit={handleSubmit}
            isSaving={isSaving}
            isPublishing={isPublishing}
            player={selectedPlayer}
            report={activeReport}
            author={account.info}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            gameVendor={game.gameVendor}
            gameVendorId={gameId}
          />
        </Flex>
      </Card>
    </>
  );
}

interface TeamTabsProps {
  value: TeamTabValues;
  handleTabChange: (value: string) => void;
  homeTeam: TeamRow;
  awayTeam: TeamRow;
  activePlayer: PlayerRow;
  reports: Record<string, ReportResponseItem>;
  handlePlayerSelected: (player: PlayerRow) => void;
}

function TeamTabs({
  value,
  handleTabChange,
  homeTeam,
  awayTeam,
  activePlayer,
  reports,
  handlePlayerSelected,
}: TeamTabsProps) {
  const sortedHomePlayers = homeTeam.currentPlayers.sort(sortByMetadataAndName);
  const sortedAwayPlayers = awayTeam.currentPlayers.sort(sortByMetadataAndName);

  return (
    <Tabs
      defaultValue="home"
      value={value}
      className="w-full"
      onValueChange={handleTabChange}
    >
      <TabsList>
        <TabsTrigger value="away">
          <span>{awayTeam.name}</span>
        </TabsTrigger>
        <TabsTrigger value="home">
          <span>{homeTeam.name}</span>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="away">
        <PlayerList
          players={sortedAwayPlayers}
          activePlayer={activePlayer}
          reports={reports}
          onPlayerClicked={handlePlayerSelected}
          className="hidden md:flex"
        />
        <Select
          className="w-full md:hidden"
          value={`${activePlayer.id}`}
          onValueChange={(value) => {
            const player = awayTeam.currentPlayers.find(
              (player) => `${player.id}` === value
            );
            if (player) {
              handlePlayerSelected(player);
            }
          }}
        >
          {sortedAwayPlayers.map((player) => (
            <SelectOption key={player.id} value={`${player.id}`}>
              <PlayerReportItem
                player={player}
                reports={reports}
                activePlayer={activePlayer}
              />
            </SelectOption>
          ))}
        </Select>
      </TabsContent>
      <TabsContent value="home">
        <PlayerList
          players={sortedHomePlayers}
          activePlayer={activePlayer}
          reports={reports}
          onPlayerClicked={handlePlayerSelected}
          className="hidden md:flex"
        />
        <Select
          className="w-full md:hidden"
          value={`${activePlayer.id}`}
          onValueChange={(value) => {
            const player = homeTeam.currentPlayers.find(
              (player) => `${player.id}` === value
            );
            if (player) {
              handlePlayerSelected(player);
            }
          }}
        >
          {sortedHomePlayers.map((player) => (
            <SelectOption key={player.id} value={`${player.id}`}>
              <PlayerReportItem
                player={player}
                reports={reports}
                activePlayer={activePlayer}
              />
            </SelectOption>
          ))}
        </Select>
      </TabsContent>
    </Tabs>
  );
}

function CreateGameReportLoading() {
  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Create Report</Text>
      </Flex>
      <Card className="mb-4">
        <GameHeroSkeleton />
      </Card>
      <Card className="grid grid-cols-4 space-x-8">
        <Flex direction="down">
          <SkeletonText rows={5} />
        </Flex>
        <PlayerHeaderSkeleton />
      </Card>
    </>
  );
}
