import { apolloApi, getGame } from "@/shared/api";
import { GameResponse, TeamsResponse } from "@/swagger";
import { LeagueId } from "@suns/api";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { ScoreboardGame } from "@suns/api/generated-client/stats";

export interface ReportGame {
  leagueId?: LeagueId;
  gameId?: string;
  gameClock?: string;
  gameStatus?: number;
  gameTimeUTC?: string;
  gameVendor: ReportResponseItem.gameVendor;
  period?: number;
  homeTeam: {
    id?: number;
    image?: string;
    tricode?: string;
    score?: number;
  };
  awayTeam: {
    id?: number;
    image?: string;
    tricode?: string;
    score?: number;
  };
}

export async function reportLoader({
  gameVendorId,
  authorUsername,
  fetchReports = true,
}: {
  gameVendorId: string;
  authorUsername?: string;
  fetchReports?: boolean;
}) {
  let homeTeam;
  let awayTeam;
  let scoreboardGame: ScoreboardGame | null = null;
  let game: ReportGame;
  let reportsResponse;

  const reportsRequest = fetchReports
    ? apolloApi.getReports({
        gameVendorId: gameVendorId,
        authorUsername: authorUsername,
      })
    : null;

  if (gameVendorId.startsWith("00")) {
    scoreboardGame = await getGame({ gameId: gameVendorId });

    const homeTeamRequest = apolloApi.getTeams({
      nbaId: `${scoreboardGame.homeTeam?.teamId}`,
      includePlayers: true,
    });

    const awayTeamRequest = apolloApi.getTeams({
      nbaId: `${scoreboardGame.awayTeam?.teamId}`,
      includePlayers: true,
    });

    let homeTeams: TeamsResponse;
    let awayTeams: TeamsResponse;
    [reportsResponse, homeTeams, awayTeams] = await Promise.all([
      reportsRequest,
      homeTeamRequest,
      awayTeamRequest,
    ]);

    homeTeam = homeTeams && homeTeams.count > 0 ? homeTeams.teams[0] : null;
    awayTeam = awayTeams && awayTeams.count > 0 ? awayTeams.teams[0] : null;
    game = {
      leagueId: LeagueId.NBA,
      gameId: scoreboardGame?.gameId ?? undefined,
      gameClock: scoreboardGame.gameClock ?? undefined,
      gameStatus: scoreboardGame.gameStatus || 1,
      gameTimeUTC: scoreboardGame.gameTimeUTC,
      gameVendor: ReportResponseItem.gameVendor.NBA,
      period: scoreboardGame.period,
      homeTeam: {
        id: scoreboardGame.homeTeam!.teamId,
        tricode: scoreboardGame.homeTeam!.teamTricode ?? undefined,
        score: scoreboardGame.homeTeam!.score,
      },
      awayTeam: {
        id: scoreboardGame.awayTeam?.teamId,
        tricode: scoreboardGame.awayTeam?.teamTricode ?? undefined,
        score: scoreboardGame.awayTeam?.score,
      },
    };
  } else {
    const gameRequest = apolloApi.getGameById(Number(gameVendorId));
    let gameResponse: GameResponse | null = null;
    [reportsResponse, gameResponse] = await Promise.all([
      reportsRequest,
      gameRequest,
    ]);

    homeTeam = (await gameRequest).game.homeTeam;
    awayTeam = (await gameRequest).game.awayTeam;
    game = {
      gameTimeUTC: gameResponse.game.gameTimeUTC ?? undefined,
      gameVendor: ReportResponseItem.gameVendor.APOLLO,
      homeTeam: {
        image: homeTeam?.image,
        tricode: gameResponse.game.homeTeam?.code ?? undefined,
      },
      awayTeam: {
        image: awayTeam?.image,
        tricode: gameResponse.game.awayTeam?.code ?? undefined,
      },
    };
  }

  const reports =
    reportsResponse?.reports?.reduce(
      (acc, report) => {
        acc[`${report.playerId}`] = report;
        return acc;
      },
      {} as Record<string, ReportResponseItem>
    ) ?? {};

  return { reports, game, homeTeam, awayTeam };
}
