import { useAccount } from "@/shared/hooks/useAccount";
import {
  Button,
  Card,
  Container,
  Flex,
  SkeletonHeading,
  SkeletonText,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { Link, useParams } from "react-router-dom";
import { viewReportLoader } from "./loaders/view-report-loader";
import { PencilIcon, PrinterIcon } from "@suns/design-system/icons";
import { GameHero, PlayerHeader, PlayerHeaderSkeleton } from "@/components";
import { GameReportForm } from "../components/ReportForm/ReportForm";
import { URL } from "@/shared/utils/route-constant";
import dayjs from "dayjs";
import Page from "@/components/Page/Page";
import { LeagueId, SunsApiError } from "@suns/api";

export function ReportView() {
  const { reportId } = useParams();
  const account = useAccount();

  const { loading, response, error } = useAsync(viewReportLoader, {
    reportId: reportId || "",
  });

  return (
    <Page
      title={
        response
          ? `${response.report.player.firstName} ${response.report.player.lastName}`
          : "View Report"
      }
      loading={loading}
      loadingState={<ReportViewLoading />}
      render={() => {
        if (!account.info) {
          throw new Error("User Authorization Error");
        }

        if (error) {
          throw new SunsApiError("Error loading report", { cause: error });
        }

        const { report, game, homeTeam, awayTeam } = response!;

        if (!homeTeam || !awayTeam) {
          throw new Error("Error loading report data");
        }

        const playerTeam =
          report.player.currentTeamId === homeTeam.id ? homeTeam : awayTeam;

        return (
          <Container size="xl">
            <Card>
              <Flex direction="down" align="center" className="w-full">
                <Flex direction="down" className="max-w-4xl" gap="lg">
                  <Flex className="absolute right-4 print:hidden" gap="sm">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <PrinterIcon size={16} />
                    </Button>
                    <Button type="button" variant="outline">
                      <Link
                        to={`/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${report.id}`}
                      >
                        <Flex direction="right" align="center" gap="sm">
                          <PencilIcon size={16} />
                          Edit
                        </Flex>
                      </Link>
                    </Button>
                  </Flex>
                  <Flex direction="right" justify="between" align="center">
                    <Flex direction="down" gap="sm">
                      <Text heading size="3xl">
                        Scouting report
                      </Text>
                      <Flex direction="right" gap="xs">
                        <Text heading>{report.authorName}</Text>
                        <Text muted>on</Text>
                        <Text heading>
                          {dayjs(report.createdAt).format("MMMM Do YYYY")}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex className="hidden rounded-md border px-4 py-2 print:flex">
                      <GameHero
                        leagueId={game.leagueId}
                        gameStatus={game.gameStatus || 1}
                        gameTimeUTC={game.gameTimeUTC}
                        gameClock={game.gameClock}
                        period={game.period}
                        homeTeam={{
                          id: game.homeTeam?.id,
                          image: game.homeTeam?.image,
                          tricode: game.homeTeam?.tricode,
                          score: game.homeTeam!.score,
                        }}
                        awayTeam={{
                          id: game.awayTeam?.id,
                          image: game.awayTeam?.image,
                          tricode: game.awayTeam?.tricode,
                          score: game.awayTeam?.score,
                        }}
                      />
                    </Flex>
                  </Flex>

                  <Flex className="hidden md:flex">
                    <PlayerHeader
                      {...report.player}
                      variant="wide"
                      teamNbaId={playerTeam.nbaId}
                      teamName={playerTeam.name}
                      playerImage={report.player.image}
                      teamImage={playerTeam.image}
                      leagueId={report.player.nbaId ? LeagueId.NBA : undefined}
                    />
                  </Flex>
                  <Flex className="md:hidden">
                    <PlayerHeader
                      {...report.player}
                      variant="compact"
                      teamNbaId={playerTeam.nbaId}
                      teamName={playerTeam.name}
                      playerImage={report.player.image}
                      teamImage={playerTeam.image}
                      leagueId={report.player.nbaId ? LeagueId.NBA : undefined}
                    />
                  </Flex>
                  <Flex
                    className="pointer-events-none"
                    onClickCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <GameReportForm
                      readonly={true}
                      report={report}
                      player={report.player}
                      gameVendor={game.gameVendor}
                      gameVendorId={game.gameId ?? ""}
                      homeTeam={homeTeam}
                      awayTeam={awayTeam}
                      author={account.info}
                      isSaving={false}
                      onSubmit={() => {
                        // noop
                      }}
                    />
                  </Flex>
                  <Flex className="print:hidden">
                    <Button type="button" variant="outline">
                      <Link
                        to={`/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${report.id}`}
                      >
                        <Flex direction="right" align="center" gap="sm">
                          <PencilIcon size={16} />
                          Edit
                        </Flex>
                      </Link>
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Container>
        );
      }}
    />
  );
}

function ReportViewLoading() {
  return (
    <Container size="xl">
      <Card>
        <Flex justify="center">
          <Flex className="w-full max-w-4xl" direction="down" gap="lg">
            <Flex direction="down" gap="sm">
              <SkeletonHeading size="3xl" />
              <SkeletonHeading />
            </Flex>
            <PlayerHeaderSkeleton />
            <SkeletonText rows={2} />
            <SkeletonText rows={3} />
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
