import { IntelRow, IntelUpsertParams } from "@suns/api/generated-client/apollo";
import { IntelFormSchema } from "./components/IntelForm";

export function intelResponseToFormData(intel: IntelRow): IntelFormSchema {
  return {
    id: intel.id,
    playerId: intel.players?.[0]?.id || undefined,
    teamId: intel.teams?.[0]?.id || undefined,
    level: intel.level,
    status: intel.status,
    authorUsername: intel.authorUsername,
    authorName: intel.authorName,
    notes: intel.notes,
    source: intel.source,
    trustworthiness: intel.trustworthiness,
    tags: intel.tags,
  };
}

export function intelFormDataToUpsertParams(
  intel: IntelFormSchema
): IntelUpsertParams {
  const { playerId, teamId, ...restIntel } = intel;
  if (!playerId && !teamId) {
    throw new Error("A player or team needs to be set for intel.");
  }

  return {
    ...restIntel,
    playerIds: playerId ? [playerId] : [],
    teamIds: teamId ? [teamId] : [],
    id: intel.id || undefined,
    tags: intel.tags,
  };
}
