/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReportQueryParams = {
    offset?: number;
    limit?: number;
    ids?: Array<number>;
    playerId?: string;
    gameVendorId?: string;
    authorUsername?: string;
    status?: ReportQueryParams.status;
};
export namespace ReportQueryParams {
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
}

