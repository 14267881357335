import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { allReportsLoader } from "./loaders/all-reports-loader";
import ReportListItem from "./components/ReportListItem";
import { PaginationControl } from "@/components";
import { useState } from "react";
import { allIntelLoader } from "./loaders/all-intel-loader";
import { IntelListItem } from "@/pages/intel/intel-listings/components/IntelListItem";
import { SunsApiError } from "@suns/api";

const PAGE_SIZE = 20;

export function AllReportsListings() {
  const [activeTab, setActiveTab] = useState("reports");
  const [reportsPage, setReportsPage] = useState(0);
  const [intelPage, setIntelPage] = useState(0);

  const { response: reportsResponse, error: reportsError } = useAsync(
    allReportsLoader,
    {
      offset: reportsPage * PAGE_SIZE,
      limit: PAGE_SIZE,
    }
  );

  const { response: intelResponse, error: intelError } = useAsync(
    allIntelLoader,
    {
      offset: intelPage * PAGE_SIZE,
      limit: PAGE_SIZE,
    }
  );

  if (reportsError) {
    throw new SunsApiError("Unable to load reports.", { cause: reportsError });
  }

  if (intelError) {
    throw new SunsApiError("Unable to load intel.", { cause: intelError });
  }

  return (
    <Card
      header={
        <Text size="xl" heading>
          All reports
        </Text>
      }
    >
      <Tabs onValueChange={setActiveTab} value={activeTab}>
        <TabsList>
          <TabsTrigger value="reports">
            <Text>Reports</Text>
          </TabsTrigger>
          <TabsTrigger value="intel">
            <Text>Intel</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="reports">
          {!reportsResponse ? (
            <ReportsLoading />
          ) : (
            <>
              {reportsResponse.reports.length > 0 ? (
                <Flex direction="down" className="divide-y-2">
                  {reportsResponse.reports.map((report) => (
                    <ReportListItem
                      key={`report-${report.id}`}
                      className="py-4"
                      report={report}
                    />
                  ))}
                </Flex>
              ) : (
                <Text className="py-4" size="lg" muted>
                  No reports found.
                </Text>
              )}
              <PaginationControl
                {...reportsResponse}
                onPageClicked={(page) => {
                  setReportsPage(page - 1);
                  window.scrollTo({ top: 0 });
                }}
              />
            </>
          )}
        </TabsContent>
        <TabsContent value="intel">
          {!intelResponse ? (
            <ReportsLoading />
          ) : (
            <>
              {intelResponse.intel.length > 0 ? (
                <Flex direction="down" className="divide-y-2">
                  {intelResponse.intel.map((intel) => (
                    <IntelListItem
                      key={`report-${intel.id}`}
                      className="py-4"
                      intel={intel}
                    />
                  ))}
                </Flex>
              ) : (
                <Text className="py-4" size="lg" muted>
                  No intel found.
                </Text>
              )}
              <PaginationControl
                {...intelResponse}
                onPageClicked={(page) => {
                  setIntelPage(page - 1);
                  window.scrollTo({ top: 0 });
                }}
              />
            </>
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function ReportsLoading() {
  return (
    <Flex className="py-4" direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
