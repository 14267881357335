import { apolloApi } from "@/shared/api";
import { reportLoader } from "../../report-loader";

export async function viewReportLoader({ reportId }: { reportId: string }) {
  const reportResponse = await apolloApi.getReport(parseInt(reportId, 10));
  const { game, homeTeam, awayTeam } = await reportLoader({
    gameVendorId: reportResponse.report.gameVendorId,
    fetchReports: false,
  });

  return {
    report: reportResponse.report,
    game,
    homeTeam,
    awayTeam,
  };
}
