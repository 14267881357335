import { Mercury, Suns } from "@/shared/const";

type TeamKey = "homeTeam" | "awayTeam";

export function getTeamKeys({ awayTeamId }: { awayTeamId: number }): {
  teamKey: TeamKey;
  oppTeamKey: TeamKey;
} {
  const teamKey = [Suns.id, Mercury.id].includes(awayTeamId)
    ? "awayTeam"
    : "homeTeam";
  const oppTeamKey = teamKey == "homeTeam" ? "awayTeam" : "homeTeam";
  return { teamKey, oppTeamKey };
}
