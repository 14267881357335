import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  TextEditor,
  Button,
  Flex,
  RadioGroup,
  RadioGroupItem,
  Input,
} from "@suns/design-system";
import { FormLabelWithTooltip } from "@/pages/reports/components/ReportForm/FormLabelWithTooltip";
import { tags } from "../tags";
import { FormTags } from "./FormTags";
import { IntelUpsertParams } from "@/swagger";
import { IntelTag } from "@suns/api/generated-client/apollo";
import { toast } from "react-toastify";
import { LoaderCircle } from "@suns/design-system/icons";
import * as yup from "yup";

const formSchema = yup.object({
  id: yup.number().notRequired(),
  playerId: yup.number().notRequired(),
  teamId: yup.number().notRequired(),
  level: yup
    .string()
    .oneOf(Object.values(IntelUpsertParams.level))
    .requiredOnPublish(),
  status: yup
    .string()
    .oneOf(Object.values(IntelUpsertParams.status))
    .requiredOnPublish(),
  authorUsername: yup.string().required(),
  authorName: yup.string().required(),
  notes: yup.string().requiredOnPublish(),
  source: yup.string().requiredOnPublish(),
  trustworthiness: yup.number().requiredOnPublish(),
  tags: yup.array(yup.string().oneOf(Object.values(IntelTag)).defined()),
});

export type IntelFormSchema = yup.InferType<typeof formSchema>;

interface IntelFormProps {
  intel: IntelFormSchema;
  onPublish: (data: IntelFormSchema, type: "publish") => void;
  onSave: (data: IntelFormSchema, type: "save") => void;
  saving: boolean;
}

export default function IntelForm({
  intel,
  onPublish,
  onSave,
  saving,
}: IntelFormProps) {
  const form = useForm<IntelFormSchema>({
    defaultValues: intel,
    mode: "onChange",
  });

  const filteredTagsByLevel = tags.filter(
    (tag) => tag.PlayerLevel === intel.level || tag.PlayerLevel === "all"
  );

  async function handleSave() {
    const values = form.getValues();

    onSave({ ...values, status: intel.status }, "save");
  }

  async function handlePublish() {
    const values = form.getValues();

    try {
      await formSchema.validate(values, {
        abortEarly: false,
        context: { publish: true },
      });

      values.status =
        intel.status === IntelUpsertParams.status.PUBLISHED
          ? IntelUpsertParams.status.UNPUBLISHED
          : IntelUpsertParams.status.PUBLISHED;
      onPublish(values, "publish");
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        error.inner.forEach((e) => {
          if (e.path) {
            form.setError(e.path as keyof IntelFormSchema, {
              type: "custom",
              message: e.message,
            });
          }
        });

        toastError("Unable to publish report. All scores are required.");
      }
    }
  }

  return (
    <Form {...form}>
      <form>
        <FormField
          name="notes"
          render={({ field }) => (
            <FormItem className="mt-4">
              <FormLabel className=" text-sm font-bold">Intel</FormLabel>
              <FormControl>
                <TextEditor {...field} defaultValue={intel?.notes} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          name="tags"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <FormTags {...field} options={filteredTagsByLevel} />
              </FormControl>
            </FormItem>
          )}
        />
        <Flex gap={["sm", "md:lg"]} align="center" wrap>
          <FormField
            name="source"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-sm ">Source</FormLabel>
                <FormControl>
                  <Input {...field} value={field.value || ""} type="text" />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            name="trustworthiness"
            render={({ field }) => (
              <FormItem>
                <FormLabelWithTooltip
                  className="mt-1 text-sm"
                  tooltip={"description"}
                  title={"Trustworthiness"}
                />
                <FormControl>
                  <RadioGroup
                    className="flex flex-col"
                    value={field.value?.toString()}
                    onValueChange={(value) => field.onChange(Number(value))}
                  >
                    <Flex direction="right" gap="sm">
                      {[...Array(5).keys()].map((_, i) => (
                        <RadioGroupItem
                          key={`trustworthiness-${i + 1}`}
                          value={`${i + 1}`}
                        />
                      ))}
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
        </Flex>

        <Flex direction="right" gap="sm">
          <Button
            type="button"
            variant="muted"
            onClick={handlePublish}
            className="my-4"
            disabled={saving}
          >
            {intel.status === IntelUpsertParams.status.PUBLISHED
              ? "Unpublish"
              : "Publish"}
          </Button>
          <Button
            type="button"
            onClick={handleSave}
            className="my-4"
            disabled={saving}
          >
            {saving ? (
              <Flex align="center" gap="sm">
                Saving...
                <LoaderCircle size={18} className="animate-spin" />
              </Flex>
            ) : (
              "Save"
            )}
          </Button>
        </Flex>
      </form>
    </Form>
  );
}

function toastError(message: string) {
  toast.error(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}
