import { loginRequest } from "@/msal.config";
import { msalInstance } from "./instance";
import { getAccount } from "./account";
import { AccountInfo } from "@azure/msal-browser";
import { promptLogin } from "@/components/LoginDialog/LoginDialog";

export async function getAuthToken(): Promise<string | null> {
  let account: AccountInfo | null = null;
  account = getAccount().info;

  if (!account) {
    try {
      account = await promptLogin();

      if (!account) {
        return null;
      }
    } catch {
      return null;
    }
  }

  try {
    const { idToken } = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });

    if (!idToken) {
      return await acquireTokenPopup(account);
    }

    return idToken;
  } catch {
    return await acquireTokenPopup(account);
  }
}

async function acquireTokenPopup(account: AccountInfo) {
  try {
    const { idToken } = await msalInstance.acquireTokenPopup({
      ...loginRequest,
      account,
    });
    return idToken;
  } catch {
    return null;
  }
}
