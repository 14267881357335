import { apolloApi } from "@/shared/api";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";

export async function allReportsLoader({ offset = 0, limit = 10 }) {
  const response = await apolloApi.getReports({
    offset,
    limit,
    status: ReportResponseItem.status.PUBLISHED,
  });
  return response;
}
