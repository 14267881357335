import { useState } from "react";
import { PlayerHeader, useNavigateLogin } from "@/components";
import { useAccount } from "@azure/msal-react";
import { useNavigate, useParams } from "react-router-dom";
import { apolloApi } from "@/shared/api";
import IntelForm, { IntelFormSchema } from "./components/IntelForm";
import { Button, Card, Flex, Skeleton, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import { usePlayer } from "../intel-create/hooks/usePlayers";
import {
  IntelUpsertParams,
  PlayerRow,
} from "@suns/api/generated-client/apollo";
import { notify } from "@/components/bugsnag";
import { intelLoader } from "./loader/intel-loader";
import { useAsync } from "@/shared/hooks";
import {
  intelFormDataToUpsertParams,
  intelResponseToFormData,
} from "./intel-utils";
import { LeagueId } from "@suns/api";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "@/shared/utils/toast-messages";
import { SunsApiError } from "@suns/api";

function defaultIntel({
  playerId,
  level,
  authorUsername,
  authorName,
}: Pick<
  IntelFormSchema,
  "playerId" | "level" | "authorUsername" | "authorName"
>) {
  return {
    playerId,
    level,
    status: IntelUpsertParams.status.UNPUBLISHED,
    authorUsername,
    authorName,
  };
}

export default function IntelCreatePlayer() {
  const navigateLogin = useNavigateLogin();
  const navigate = useNavigate();
  const account = useAccount();
  const { playerId } = useParams();
  const [saving, setSaving] = useState(false);
  const [savedIntelId, setSavedIntelId] = useState<number>();
  const {
    player,
    loading: playerLoading,
    error: playerError,
  } = usePlayer(Number(playerId));
  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelLoader, {
    playerId: playerId ? Number(playerId) : undefined,
    authorUsername: account?.username ?? undefined,
  });

  const handleSave = async (intel: IntelFormSchema) => {
    try {
      setSaving(true);
      const savedIntel = await apolloApi.saveIntel({
        ...intelFormDataToUpsertParams(intel),
        id: savedIntelId,
      });

      setSavedIntelId(savedIntel.intel.id);

      if (intel.status === IntelUpsertParams.status.PUBLISHED) {
        toastSuccess("Intel Published");
        navigate(-1);
      } else {
        toastInfo("Intel Saved");
      }
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toastError("Please log back in to save.");
        navigateLogin();
      }
      notify(new Error("Error saving intel.", { cause: e }));
      toastError("Unable to save intel. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  if (intelError) {
    throw new SunsApiError("There was an error requesting intel.", {
      cause: intelError,
    });
  }

  if (playerError) {
    throw new SunsApiError("There was an error requesting a player.", {
      cause: playerError,
    });
  }

  return (
    <>
      <Flex className="mb-2" align="center">
        <Button
          size="xs"
          variant="ghost"
          className="p-0 text-sm text-gray-500"
          onClick={() => navigate("/reports/intel/create")}
        >
          Intel
        </Button>
        <ChevronRight className="text-gray-500 " size={20} />
        <Button size="xs" variant="ghost" className="p-0 text-sm text-gray-500">
          New Intel
        </Button>
      </Flex>
      <Text heading={true} size="4xl" className="mb-4">
        New Intel
      </Text>
      <Card>
        {playerLoading || intelLoading ? (
          <Flex gap="lg" className="mt-1">
            <Skeleton className="h-24 w-24 rounded-full" />
            <Flex direction="down" gap="md">
              <Skeleton className="h-10 w-20 md:w-40" />
              <Skeleton className="h-10 w-40 md:w-72" />
            </Flex>
          </Flex>
        ) : (
          <>
            <PlayerHeader
              nbaId={player!.nbaId}
              firstName={player!.firstName}
              lastName={player!.lastName}
              height={player!.height}
              weight={player!.weight}
              birthDate={player!.birthDate}
              wing={player!.wing}
              reach={player!.reach}
              playerImage={player!.image}
              teamNbaId={player!.currentTeam?.nbaId}
              teamName={player!.currentTeam?.fullName}
              teamImage={player!.currentTeam?.image}
              leagueId={
                player!.level === PlayerRow.level.PRO ? LeagueId.NBA : undefined
              }
            />

            <IntelForm
              intel={
                intel
                  ? intelResponseToFormData(intel)
                  : defaultIntel({
                      playerId: player!.id,
                      level: player!.level,
                      authorUsername: account!.username,
                      authorName: account!.name!,
                    })
              }
              onSave={handleSave}
              onPublish={handleSave}
              saving={saving}
            />
          </>
        )}
      </Card>
    </>
  );
}
