import { useAccount, useAsync } from "@/shared/hooks";
import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { myIntelLoader } from "./loaders/my-intel-loader";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";
import { useState } from "react";
import { PaginationControl } from "@/components";
import { IntelListItem } from "./components/IntelListItem";
import { SunsApiError } from "@suns/api";

const PAGE_SIZE = 20;

export function IntelListings() {
  const account = useAccount();
  const [activeTab, setActiveTab] = useState("published");
  const [publishedPage, setPublishedPage] = useState(0);
  const [unpublishedPage, setUnpublishedPage] = useState(0);

  const {
    loading: publishedLoading,
    response: publishedResponse,
    error: publishedError,
    refresh: refreshPublished,
  } = useAsync(myIntelLoader, {
    authorUsername: account.info?.username,
    status: IntelQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  const {
    loading: unpublishedLoading,
    response: unpublishedResponse,
    error: unpublishedError,
    refresh: refreshUnpublished,
  } = useAsync(myIntelLoader, {
    authorUsername: account.info?.username,
    status: IntelQueryParams.status.UNPUBLISHED,
    offset: unpublishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  if (publishedLoading || unpublishedLoading) {
    return <IntelListingsLoading />;
  }

  if (publishedError) {
    throw new SunsApiError("Unable to load intel.", { cause: publishedError });
  }

  if (unpublishedError) {
    throw new SunsApiError("Unable to load intel.", {
      cause: unpublishedError,
    });
  }

  const currentPublishedPage = Math.floor(
    publishedResponse.offset / publishedResponse.limit
  );
  if (publishedPage !== currentPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshPublished();
  }

  const currentUnpublishedPage = Math.floor(
    unpublishedResponse.offset / unpublishedResponse.limit
  );
  if (unpublishedPage !== currentUnpublishedPage) {
    window.scrollTo({ top: 0 });
    refreshUnpublished();
  }

  return (
    <Card
      header={
        <Text size="xl" heading>
          My Intel
        </Text>
      }
    >
      <Tabs onValueChange={setActiveTab} value={activeTab}>
        <TabsList>
          <TabsTrigger value="published">
            <Text>Published</Text>
          </TabsTrigger>
          <TabsTrigger value="unpublished">
            <Text>Unpublished</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="published">
          {publishedResponse.intel.length > 0 ? (
            <Flex direction="down" className="divide-y-2">
              {publishedResponse.intel.map((intel) => (
                <IntelListItem key={intel.id} className="py-4" intel={intel} />
              ))}
            </Flex>
          ) : (
            <Text className="py-4" size="lg" muted>
              You don't have any published intel.
            </Text>
          )}
          <PaginationControl
            {...publishedResponse}
            onPageClicked={(page) => {
              setPublishedPage(page - 1);
            }}
          />
        </TabsContent>
        <TabsContent value="unpublished">
          {unpublishedResponse.intel.length > 0 ? (
            <Flex direction="down" className="divide-y-2">
              {unpublishedResponse.intel.map((intel) => (
                <IntelListItem key={intel.id} className="py-4" intel={intel} />
              ))}
            </Flex>
          ) : (
            <Text className="py-4" size="lg" muted>
              You don't have any unpublished intel.
            </Text>
          )}
          <PaginationControl
            {...unpublishedResponse}
            onPageClicked={(page) => {
              setUnpublishedPage(page - 1);
            }}
          />
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function IntelListingsLoading() {
  return (
    <Card
      header={
        <Text size="xl" heading>
          My Reports
        </Text>
      }
    >
      <Flex className="py-4" direction="down" gap="lg">
        <SkeletonText />
        <SkeletonText />
      </Flex>
    </Card>
  );
}
