import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function myIntelLoader({
  authorUsername,
  status,
  offset = 0,
  limit = 20,
}: {
  authorUsername?: string;
  status:
    | IntelQueryParams.status.PUBLISHED
    | IntelQueryParams.status.UNPUBLISHED;
  offset: number;
  limit: number;
}) {
  const response = await apolloApi.getIntel({
    offset,
    limit,
    status,
    authorUsername: authorUsername ?? "",
  });

  return response;
}
