import { useAsync } from "@/shared/hooks";
import { Link, useParams } from "react-router-dom";
import { usePlayer } from "../intel-create/hooks/usePlayers";
import { PlayerHeader, PlayerHeaderSkeleton } from "@/components";
import { PlayerRow } from "@suns/api/generated-client/apollo";
import { URL } from "@/shared/utils/route-constant";

import {
  Button,
  Card,
  Flex,
  Text,
  TextEditor,
  Badge,
  Container,
  Skeleton,
  SkeletonText,
} from "@suns/design-system";
import { PencilIcon, PrinterIcon } from "@suns/design-system/icons";
import Page from "@/components/Page/Page";
import dayjs from "dayjs";
import { intelByIdLoader } from "../intel-edit/loaders/intel-edit-loader";
import { LeagueId } from "@suns/api";

export function IntelView() {
  const { playerId, intelId } = useParams();
  const {
    player,
    loading: playerLoading,
    error: playerError,
  } = usePlayer(Number(playerId));
  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelByIdLoader, {
    intelId: intelId ? Number(intelId) : undefined,
  });

  return (
    <Page
      title={
        intel
          ? `${intel?.players?.[0]?.firstName} ${intel?.players?.[0]?.lastName}`
          : "View Intel"
      }
      loading={playerLoading || intelLoading}
      loadingState={<IntelViewLoading />}
      render={() => {
        if (playerError) {
          throw Error("There was an error requesting a player.", {
            cause: playerError,
          });
        }

        if (intelError) {
          throw Error("There was an error requesting intel.", {
            cause: intelError,
          });
        }
        return (
          <Container size="xl">
            <Card>
              <Flex direction="down" align="center" className="w-full">
                <Flex direction="down" className="max-w-4xl" gap="md">
                  <Flex direction="down" gap="sm" className="mb-4">
                    <Text heading size="3xl">
                      Intel
                    </Text>
                    <Flex direction="right" gap="xs">
                      <Text heading>{intel?.authorName}</Text>
                      <Text muted>on</Text>
                      <Text heading>
                        {dayjs(intel?.createdAt).format("MMMM Do YYYY")}
                      </Text>
                    </Flex>
                  </Flex>

                  <PlayerHeader
                    nbaId={player?.nbaId || 0}
                    firstName={player?.firstName || ""}
                    lastName={player?.lastName || ""}
                    height={player?.height.toString() || "0"}
                    weight={Number(player?.weight) || 0}
                    birthDate={player?.birthDate || ""}
                    wing={Number(player?.wing) || 0}
                    reach={Number(player?.reach) || 0}
                    playerImage={player?.image || ""}
                    teamNbaId={Number(player?.currentTeam?.nbaId) || 0}
                    teamName={player?.currentTeam?.fullName || ""}
                    teamImage={""}
                    leagueId={
                      player?.level === PlayerRow.level.PRO
                        ? LeagueId.NBA
                        : undefined
                    }
                  />

                  <Flex gap={"lg"} align="center">
                    <Flex direction="down" gap="sm">
                      <Text size="lg" muted heading>
                        Source
                      </Text>
                      <Text size="lg" heading>
                        {intel?.source}
                      </Text>
                    </Flex>
                    <Flex direction="down" gap="sm">
                      <Text size="lg" muted heading>
                        Trustworthiness
                      </Text>
                      <Text size="lg" heading>
                        {intel?.trustworthiness}
                      </Text>
                    </Flex>
                  </Flex>
                  {intel?.tags?.length ? (
                    <>
                      <Flex gap="sm">
                        {intel?.tags?.map((tag) => {
                          return (
                            <Badge
                              variant="primary"
                              className="rounded-md px-2 font-normal"
                              key={tag}
                            >
                              {tag}
                            </Badge>
                          );
                        })}
                      </Flex>
                    </>
                  ) : null}
                  <TextEditor defaultValue={intel?.notes} readonly />
                  <Flex direction="right" gap="sm" className="print:hidden">
                    <Link
                      to={`/reports/${URL.INTEL.path}/${URL.INTEL.children.INTEL_EDIT.path}/${playerId}/${intelId}`}
                    >
                      <Button type="button" variant="outline">
                        <Flex direction="right" align="center" gap="sm">
                          <PencilIcon size={16} />
                          Edit
                        </Flex>
                      </Button>
                    </Link>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <PrinterIcon size={16} />
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Container>
        );
      }}
    />
  );
}

function IntelViewLoading() {
  return (
    <Container size="xl">
      <Card>
        <Flex direction="down" align="center" className="w-full">
          <Flex direction="down" className="w-3/4" gap="md">
            <Flex direction="down" gap="sm" className="mb-4">
              <Flex direction="down" gap="md">
                <Skeleton className="h-8 w-24" />
                <Skeleton className="h-4 w-72" />
              </Flex>
            </Flex>
            <PlayerHeaderSkeleton />
            <Flex gap="lg" align="center" className="mt-4">
              <Flex direction="down" gap="md">
                <Skeleton className="h-4 w-36" />
                <Skeleton className="h-4 w-24" />
              </Flex>
              <Flex direction="down" gap="md">
                <Skeleton className="h-4 w-36" />
                <Skeleton className="h-4 w-24" />
              </Flex>
            </Flex>
            <Skeleton className="my-2 h-5 w-24" />
            <Flex direction="down" gap="sm">
              <SkeletonText rows={1} className="h-4 w-full" />
              <SkeletonText rows={1} className="h-4 w-full" />
              <SkeletonText rows={1} className="h-4 w-64" />
            </Flex>
            <Skeleton className="h-10 w-20" />
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
