import { Navigate, useLocation, useNavigate } from "react-router-dom";

export function NavigateLogin() {
  const { pathname } = useLocation();
  return <Navigate to="/login" state={{ from: pathname }} replace />;
}

export function useNavigateLogin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return () => {
    navigate("/login", {
      state: { from: pathname },
      replace: true,
    });
  };
}
