import { useForm } from "react-hook-form";
import {
  Button,
  TextEditor,
  Flex,
  Textarea,
  Text,
  FormItem,
  Form,
  FormControl,
  FormField,
  FormLabel,
} from "@suns/design-system";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  PlayerRow,
  ReportApolloGrade,
  ReportPlayerAttribute,
  ReportResponseItem,
  ReportScore,
  ReportUpsertParams,
  TeamRow,
} from "@suns/api/generated-client/apollo";
import {
  ApolloGradeValueLabels,
  DefenseOffBallScores,
  DefenseOnBallScores,
  OffenseShootingScores,
  OffenseSkillScores,
  OtherScores,
  PlayerAttributeLabels,
  ReportCanDefendKeys,
  ReportPositionLabels,
  ReportRoleLabels,
  ReportScoreDescriptions,
  ReportScoutingLocationLabels,
  ReportTeamFitLabels,
} from "../../reports-const";
import { getErrorMessage, reportResponseToFormData } from "../../report-utils";
import { AccountInfo } from "@azure/msal-browser";
import { ThreeStateToggle } from "./ThreeStateToggle/ThreeStateToggle";
import { FormMultiSelect } from "./FormMultiSelect";
import { FormSelect } from "./FormSelect";
import { FormCheckboxGroup } from "./FormCheckboxGroup";
import { FormSkillSelect } from "./FormSkillSelect";
import { LoaderCircle } from "lucide-react";
import useFormPersist from "react-hook-form-persist";
import * as yup from "yup";
import { toast } from "react-toastify";

const REPORT_DEBOUNCE_TIME = 5000;

const yupFormSchema = yup.object({
  id: yup.number().notRequired(),
  playerId: yup.number().required(),
  teamId: yup.number().required(),
  type: yup.string().oneOf(Object.values(ReportResponseItem.type)).required(),
  level: yup.string().oneOf(Object.values(ReportResponseItem.level)).required(),
  status: yup
    .string()
    .oneOf(Object.values(ReportResponseItem.status))
    .required(),
  statusUpdatedAt: yup.string(),
  createdAt: yup.string(),
  updatedAt: yup.string(),
  authorUsername: yup.string().required(),
  authorName: yup.string().required(),
  teamFit: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.teamFit))
    .when("level", {
      is: ReportResponseItem.level.PRO,
      then: (teamFit) => teamFit.required(),
      otherwise: (teamFit) => teamFit.notRequired(),
    }),
  teamFitNotes: yup.string().notRequired(),
  scoutLocation: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.scoutLocation))
    .requiredOnPublish(),
  position: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.position))
    .requiredOnPublish(),
  role: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.role))
    .requiredOnPublish(),
  offensiveNotes: yup.string().notRequired(),
  defensiveNotes: yup.string().notRequired(),
  otherNotes: yup.string().notRequired(),
  playerAttributes: yup
    .array()
    .of(yup.string().oneOf(Object.keys(ReportPlayerAttribute.key)))
    .notRequired(),
  gameVendor: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.gameVendor))
    .when("type", {
      is: ReportResponseItem.type.GAME,
      then: (gameVendor) => gameVendor.required(),
      otherwise: (gameVendor) => gameVendor.notRequired(),
    }),
  gameVendorId: yup.string().when("type", {
    is: ReportResponseItem.type.GAME,
    then: (gameVendorId) => gameVendorId.required(),
    otherwise: (gameVendorId) => gameVendorId.notRequired(),
  }),
  reportIds: yup.array(yup.string()).when("type", {
    is: ReportResponseItem.type.PHASE,
    then: (reportIds) => reportIds.requiredOnPublish(),
    otherwise: (reportIds) => reportIds.notRequired(),
  }),
  scores: yup.object(
    Object.fromEntries(
      Object.values(ReportScore.key).map((key) => [
        key,
        yup.string().nullable().requiredOnPublish(),
      ])
    )
  ),
  apolloGrades: yup
    .object()
    .when(["type", "level"], {
      is: (type: string, level: string) =>
        type === ReportResponseItem.type.GAME &&
        level === ReportResponseItem.level.PRO,
      then: (apolloGrades) =>
        apolloGrades.concat(
          yup.object({
            [ReportApolloGrade.scope.CURRENT]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
          })
        ),
    })
    .when(["type", "level"], {
      is: (type: string, level: string) =>
        type === ReportResponseItem.type.PHASE &&
        level === ReportResponseItem.level.PRO,
      then: (apolloGrades) =>
        apolloGrades.concat(
          yup.object({
            [ReportApolloGrade.scope.CURRENT]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
            [ReportApolloGrade.scope.REMAINING_CAPACITY]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
          })
        ),
    })
    .when(["type", "level"], {
      is: (_type: string, level: string) =>
        level === ReportResponseItem.level.AMATEUR,
      then: (apolloGrades) =>
        apolloGrades.concat(
          yup.object({
            [ReportApolloGrade.scope.CEILING]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
            [ReportApolloGrade.scope.BULLSEYE]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
            [ReportApolloGrade.scope.BASEMENT]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
          })
        ),
    }),
});

export type GameReportFormSchema = yup.InferType<typeof yupFormSchema>;
interface GameReportFormProps {
  onSubmit: (data: GameReportFormSchema, publish?: boolean) => void;
  isSaving: boolean;
  isPublishing?: boolean;
  player: PlayerRow;
  report: ReportResponseItem | null;
  author: AccountInfo;
  homeTeam: TeamRow;
  awayTeam: TeamRow;
  readonly?: boolean;
  gameVendor: ReportResponseItem.gameVendor;
  gameVendorId: string;
}

export function GameReportForm({
  onSubmit,
  isSaving,
  isPublishing,
  report,
  player,
  author,
  homeTeam,
  awayTeam,
  readonly = false,
  gameVendor,
  gameVendorId,
}: GameReportFormProps) {
  const defaultValues = reportResponseToFormData(
    report,
    player,
    author,
    homeTeam,
    awayTeam,
    gameVendor,
    gameVendorId
  );

  const form = useForm<GameReportFormSchema>({
    defaultValues,
    mode: "onChange",
  });

  const { watch, setValue } = form;

  useFormPersist(`report.${player.id}.${gameVendorId}`, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  async function handlePublish() {
    const values = form.getValues();
    if (values.status === ReportUpsertParams.status.PUBLISHED) {
      setValue("status", ReportUpsertParams.status.UNPUBLISHED);
      debounced({ publish: true });
      debounced.flush();
    } else {
      try {
        await yupFormSchema.validate(values, {
          abortEarly: false,
          context: { publish: true },
        });

        setValue("status", ReportUpsertParams.status.PUBLISHED);
        debounced({ publish: true });
        debounced.flush();
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = error.inner.map((e) => {
            if (e.path) {
              form.setError(e.path as keyof GameReportFormSchema, {
                type: "custom",
                message: e.message,
              });
            }

            return getErrorMessage(e.path?.split("."));
          });

          toast.error(
            `Unable to publish report. Missing ${errors.join(", ")}.`,
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: true,
              theme: "colored",
            }
          );
        }
      }
    }
  }

  // debounce changes by 2 seconds
  const debounced = useDebouncedCallback((options) => {
    const { publish = false } = options;

    onSubmit(form.getValues(), publish);
  }, REPORT_DEBOUNCE_TIME);

  // watch for changes and call debounced callback on changes
  useEffect(() => {
    const sub = watch(() => {
      debounced({ publish: false });
    });

    return () => {
      debounced.flush();
      sub.unsubscribe();
    };
  }, [watch, debounced]);

  return (
    <Form {...form} key={player.id}>
      <form>
        <Flex direction="down" gap="lg">
          {/* Suns Fit */}
          {player.level === "PRO" && !readonly ? (
            <Flex direction="down" gap="md">
              <FormMultiSelect
                name="teamFit"
                options={Object.values(ReportUpsertParams.teamFit).map(
                  (value) => ({
                    value,
                    label: ReportTeamFitLabels[value],
                  })
                )}
              />
              <FormField
                name="teamFitNotes"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Flex>
          ) : null}

          {/* Position/Role */}
          {readonly && report ? (
            renderReadonlyValues(report)
          ) : (
            <>
              <Flex direction="right" gap="lg">
                <FormSelect
                  title="Position"
                  name="position"
                  placeholder="Select an option"
                  options={Object.values(ReportUpsertParams.position).map(
                    (value) => ({
                      value,
                      label: ReportPositionLabels[value],
                    })
                  )}
                  labelClassName="text-lg font-bold"
                />

                <FormSelect
                  title="Role"
                  name="role"
                  placeholder="Select an option"
                  options={Object.values(ReportUpsertParams.role).map(
                    (value) => ({
                      value,
                      label: ReportRoleLabels[value],
                    })
                  )}
                  labelClassName="text-lg font-bold"
                />
              </Flex>
            </>
          )}

          {!readonly ? (
            <Flex direction="down" gap="sm">
              <Text size="lg" heading asChild>
                <FormLabel>Apollo Grade</FormLabel>
              </Text>

              {/* Apollo Grade */}
              {player.level === "PRO" ? (
                <Flex direction="right" gap="lg">
                  <FormSelect
                    title={
                      report?.type === ReportResponseItem.type.PHASE
                        ? "Today"
                        : null
                    }
                    name={`apolloGrades.${ReportApolloGrade.scope.CURRENT}`}
                    placeholder="Select an option"
                    options={Object.values(ReportApolloGrade.value).map(
                      (value) => ({
                        value,
                        label: ApolloGradeValueLabels[value],
                      })
                    )}
                  />

                  {report?.type === ReportResponseItem.type.PHASE ? (
                    <FormSelect
                      title="Remaining capacity"
                      name={`apolloGrades.${ReportApolloGrade.scope.REMAINING_CAPACITY}`}
                      placeholder="Select an option"
                      options={Object.values(ReportApolloGrade.value).map(
                        (value) => ({
                          value,
                          label: ApolloGradeValueLabels[value],
                        })
                      )}
                    />
                  ) : null}
                </Flex>
              ) : (
                <Flex direction="right" gap="lg">
                  <FormSelect
                    title="Ceiling"
                    name={`apolloGrades.${ReportApolloGrade.scope.CEILING}`}
                    placeholder="Select an option"
                    options={Object.values(ReportApolloGrade.value).map(
                      (value) => ({
                        value,
                        label: ApolloGradeValueLabels[value],
                      })
                    )}
                  />
                  <FormSelect
                    title="Bullseye"
                    name={`apolloGrades.${ReportApolloGrade.scope.BULLSEYE}`}
                    placeholder="Select an option"
                    options={Object.values(ReportApolloGrade.value).map(
                      (value) => ({
                        value,
                        label: ApolloGradeValueLabels[value],
                      })
                    )}
                  />
                  <FormSelect
                    title="Basement"
                    name={`apolloGrades.${ReportApolloGrade.scope.BASEMENT}`}
                    placeholder="Select an option"
                    options={Object.values(ReportApolloGrade.value).map(
                      (value) => ({
                        value,
                        label: ApolloGradeValueLabels[value],
                      })
                    )}
                  />
                </Flex>
              )}
            </Flex>
          ) : null}

          <Flex direction="down" gap="sm">
            <Text size="lg" heading asChild>
              <FormLabel>Player 15 Eval</FormLabel>
            </Text>
            <FormCheckboxGroup
              name="playerAttributes"
              options={Object.values(ReportPlayerAttribute.key).map(
                (value) => ({
                  value,
                  label: PlayerAttributeLabels[value],
                })
              )}
              multiselect={true}
            />
          </Flex>

          <Flex direction="down" gap="md">
            <Text size="lg" heading asChild>
              <FormLabel>Offense</FormLabel>
            </Text>
            <Flex direction="down" gap="md" className="break-inside-avoid">
              <Text size="md" muted heading asChild>
                <FormLabel>Skill</FormLabel>
              </Text>
              <Flex direction="right" gap="lg" wrap>
                {OffenseSkillScores.map((score) => (
                  <FormSkillSelect
                    key={score}
                    name={`scores.${score}`}
                    label={ReportScoreDescriptions[score].label}
                    tooltip={ReportScoreDescriptions[score].description}
                  />
                ))}
              </Flex>
            </Flex>

            <Flex direction="down" gap="md" className="break-inside-avoid">
              <Text size="md" className="mt-4" muted heading asChild>
                <FormLabel>Shooting</FormLabel>
              </Text>
              <Flex direction="right" gap="lg" wrap>
                {OffenseShootingScores.map((score) => (
                  <FormSkillSelect
                    key={score}
                    name={`scores.${score}`}
                    label={ReportScoreDescriptions[score].label}
                    tooltip={ReportScoreDescriptions[score].description}
                  />
                ))}
              </Flex>
            </Flex>
          </Flex>

          <FormField
            name="offensiveNotes"
            render={({ field }) => (
              <FormItem>
                <Text size="md" muted heading asChild>
                  <FormLabel>Offensive notes</FormLabel>
                </Text>
                <FormControl>
                  <TextEditor
                    readonly={readonly}
                    className="h-44"
                    {...field}
                    defaultValue={defaultValues.offensiveNotes}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <Flex direction="down" gap="md">
            <Text size="lg" heading asChild>
              <FormLabel>Defense</FormLabel>
            </Text>

            <Flex direction="down" gap="md" className="break-inside-avoid">
              <Text size="md" muted heading asChild>
                <FormLabel>Can defend</FormLabel>
              </Text>

              <Flex direction="right" gap="sm">
                {ReportCanDefendKeys.map((score) => (
                  <FormField
                    key={score}
                    name={`scores.${score}`}
                    render={({ field }) => (
                      <FormItem key={score}>
                        <FormControl>
                          <ThreeStateToggle
                            {...field}
                            value={field.value ? Number(field.value) : 0}
                            onChange={(value) => {
                              field.onChange(`${value}`);
                            }}
                          >
                            {ReportScoreDescriptions[score].label}
                          </ThreeStateToggle>
                        </FormControl>
                      </FormItem>
                    )}
                  />
                ))}
              </Flex>
            </Flex>

            <Text size="md" muted heading asChild>
              <FormLabel>On-ball</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {DefenseOnBallScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>

            <Text size="md" className="mt-4" muted heading asChild>
              <FormLabel>Off-ball</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {DefenseOffBallScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>

            <FormField
              name="defensiveNotes"
              render={({ field }) => (
                <FormItem>
                  <Text size="md" muted heading asChild>
                    <FormLabel>Defensive notes</FormLabel>
                  </Text>
                  <FormControl>
                    <TextEditor
                      readonly={readonly}
                      className="h-44"
                      {...field}
                      defaultValue={defaultValues.defensiveNotes}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Flex>

          <Flex direction="down" gap="md">
            <Text size="md" heading asChild>
              <FormLabel>Other</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {OtherScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>

            <FormField
              name="otherNotes"
              render={({ field }) => (
                <FormItem>
                  <Text size="md" muted heading asChild>
                    <FormLabel>Other notes</FormLabel>
                  </Text>
                  <FormControl>
                    <TextEditor
                      className="h-44"
                      readonly={readonly}
                      {...field}
                      defaultValue={defaultValues.otherNotes}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Flex>

          <Flex direction="down" gap="sm">
            <Text size="lg" heading asChild>
              <FormLabel>Scouting location</FormLabel>
            </Text>
            <FormCheckboxGroup
              name="scoutLocation"
              options={Object.values(ReportUpsertParams.scoutLocation).map(
                (value) => ({
                  value,
                  label: ReportScoutingLocationLabels[value],
                })
              )}
              multiselect={false}
            />
          </Flex>
        </Flex>

        {!readonly ? (
          <Flex direction="right" gap="md" className="mt-6">
            <FormField
              name="status"
              render={({ field }) => {
                return (
                  <Button
                    type="button"
                    variant="muted"
                    disabled={isSaving || isPublishing}
                    onClick={handlePublish}
                  >
                    {isPublishing ? (
                      <LoaderCircle size={18} className="animate-spin" />
                    ) : field?.value === ReportUpsertParams.status.PUBLISHED ? (
                      "Unpublish"
                    ) : (
                      "Publish"
                    )}
                  </Button>
                );
              }}
            />
            <Button
              type="button"
              disabled={isSaving || isPublishing}
              onClick={() => {
                debounced({ publish: false });
                debounced.flush();
              }}
            >
              {isSaving ? (
                <Flex direction="right" gap="sm" align="center">
                  Saving...
                  <LoaderCircle size={18} className="animate-spin" />
                </Flex>
              ) : (
                <Flex>Save</Flex>
              )}
            </Button>
          </Flex>
        ) : null}
      </form>
    </Form>
  );
}

function renderReadonlyValues(report: ReportResponseItem) {
  const grade = report.apolloGrades.find(
    (grade) => grade.scope === ReportApolloGrade.scope.CURRENT
  );
  const gradeDisplay = grade ? ApolloGradeValueLabels[grade.value] : null;
  const position = report.position
    ? ReportPositionLabels[report.position]
    : null;
  const role = report.role ? ReportRoleLabels[report.role] : null;
  const teamFit = report.teamFit ? ReportTeamFitLabels[report.teamFit] : null;

  return (
    <Flex direction="down" gap="lg">
      <Flex direction="down" gap="sm">
        {teamFit ? (
          <Flex direction="right" gap="sm">
            <Text heading muted size="lg">
              Suns Fit
            </Text>
            <Text heading size="lg">
              {teamFit}
            </Text>
          </Flex>
        ) : null}

        {report.teamFitNotes ? (
          <Text size="sm" className="whitespace-pre text-wrap">
            {report.teamFitNotes}
          </Text>
        ) : null}
      </Flex>

      <Flex direction="right" gap="lg">
        {gradeDisplay ? (
          <Flex direction="down" gap="xs">
            <Text size="md" muted heading>
              Grade
            </Text>
            <Text size="lg" heading>
              {gradeDisplay}
            </Text>
          </Flex>
        ) : null}
        {position ? (
          <Flex direction="down" gap="xs">
            <Text size="md" muted heading>
              Position
            </Text>
            <Text size="lg" heading>
              {position}
            </Text>
          </Flex>
        ) : null}
        {role ? (
          <Flex direction="down" gap="xs">
            <Text size="md" muted heading>
              Role
            </Text>
            <Text size="lg" heading>
              {role}
            </Text>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
}
