import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Flex, Text } from "@suns/design-system";
import dayjs from "dayjs";

import { TeamLogo } from "@/components";
import { Suns } from "@/shared/const";
import { LeagueId } from "@suns/api";
import { Game } from "../../game-dna-listings-loader";

export function GameCard({ ...props }: Game) {
  const {
    gameId,
    homeTeamId,
    homeTeamAbbr,
    homeTeamScore,
    awayTeamId,
    awayTeamAbbr,
    awayTeamScore,
    gameDateTimeUtc,
    gameWinnerTeamId,
    gameNumber,
  } = props;
  const date = useMemo(
    () => dayjs(gameDateTimeUtc).tz(dayjs.tz.guess() ?? "America/Phoenix"),
    [gameDateTimeUtc]
  );

  const showScore = awayTeamScore && homeTeamScore;

  return (
    <Link to={`/game/${gameId}`}>
      <Flex>
        <Flex
          direction="down"
          className="mr-4 w-52 flex-1 border-r-2 border-gray-300 pr-4"
        >
          <GameCardTeam
            id={awayTeamId}
            abbr={awayTeamAbbr}
            score={showScore ? awayTeamScore : undefined}
            isWinner={awayTeamId == gameWinnerTeamId}
          />
          <GameCardTeam
            id={homeTeamId}
            abbr={homeTeamAbbr}
            score={showScore ? homeTeamScore : undefined}
            isWinner={homeTeamId == gameWinnerTeamId}
          />
        </Flex>

        <Flex
          direction="down"
          justify="between"
          align="start"
          className="w-1/3 py-2"
        >
          {gameNumber ? <Text heading>Game {gameNumber}</Text> : null}
          <Text>{date.format("MMMM D, YYYY")}</Text>
          <Button variant="link" size="flush">
            Game Stats
          </Button>
        </Flex>
      </Flex>
    </Link>
  );
}

function GameCardTeam({
  id,
  abbr,
  score,
  isWinner,
}: {
  id: number;
  abbr: string;
  score?: number;
  isWinner: boolean;
}) {
  const scoreClass = useMemo(() => {
    if (id != Suns.id) {
      return "";
    }

    return isWinner ? "text-green-600" : "text-red-600";
  }, [id, isWinner]);

  return (
    <Flex
      align="center"
      gap="sm"
      justify={score ? "between" : "start"}
      direction="right"
    >
      <TeamLogo teamId={id} leagueId={LeagueId.NBA} size="sm" />
      <Text size="2xl" heading>
        {abbr}
      </Text>
      {score ? (
        <Text size="2xl" className={`ml-auto ${scoreClass}`}>
          {score}
        </Text>
      ) : null}
    </Flex>
  );
}
