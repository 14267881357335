import {
  PlayerRow,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { Button, cn, Flex } from "@suns/design-system";
import { PlayerReportItem } from "./PlayerReportItem";

interface PlayerListParams {
  players: PlayerRow[];
  activePlayer: PlayerRow | null;
  reports: Record<string, ReportResponseItem>;
  onPlayerClicked: (player: PlayerRow) => void;
  className?: string;
}

export function PlayerList({
  players,
  activePlayer,
  reports,
  onPlayerClicked,
  className,
}: PlayerListParams) {
  return (
    <Flex direction="down" align="start" className={className}>
      {players.map((player) => {
        const isActivePlayer = activePlayer?.id === player.id;

        return (
          <Button
            key={player.id}
            variant="link"
            onClick={() => onPlayerClicked(player)}
            className={cn(
              isActivePlayer ? "text-foreground" : "text-muted-400",
              "px-0"
            )}
          >
            <PlayerReportItem
              player={player}
              reports={reports}
              activePlayer={activePlayer}
            />
          </Button>
        );
      })}
    </Flex>
  );
}
