import { Suspense } from "react";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import ErrorFallback from "@/components/error-fallback/error-fallback";
import { RouteObject } from "react-router-dom";
import { IntelCreate, IntelListings, IntelView } from "@/pages/intel/index";
import IntelCreateReport from "@/pages/intel/intel-create-player/intel-create-player";
import IntelEditPlayer from "@/pages/intel/intel-edit/intel-edit-player";

export const IntelRoutes = {
  path: "reports/intel",
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelCreate />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create/player/:playerId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelCreateReport />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "edit/:playerId/:intelId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelEditPlayer />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "view/:playerId/:intelId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
  ],
} satisfies RouteObject;

export default IntelRoutes;
