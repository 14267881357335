import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function intelLoader({
  playerId,
  authorUsername,
}: {
  playerId?: number;
  authorUsername?: string;
}) {
  if (!playerId || !authorUsername) {
    return null;
  }

  const response = await apolloApi.getIntel({
    playerId: playerId,
    authorUsername,
    limit: 1,
    status: IntelQueryParams.status.UNPUBLISHED,
  });

  return response?.intel[0] || null;
}
