import { useAccount } from "@/shared/hooks/useAccount";
import { Card, Flex, SkeletonText, Text } from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { useParams } from "react-router-dom";
import { editReportLoader } from "./loaders/edit-report-loader";
import { ChevronRight } from "@suns/design-system/icons";
import { GameHero, GameHeroSkeleton } from "@/components/GameHero/GameHero";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
  useNavigateLogin,
} from "@/components";
import { reportFormDataToUpsertParams } from "../report-utils";
import { apolloApi } from "@/shared/api";
import {
  GameReportForm,
  GameReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { useState } from "react";
import { PlayerRow } from "@/swagger";
import { LeagueId, SunsApiError } from "@suns/api";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "@/shared/utils/toast-messages";

export function ReportEdit() {
  const navigateLogin = useNavigateLogin();
  const { reportId } = useParams();
  const account = useAccount();
  const [isSaving, setIsSaving] = useState(false);

  const { loading, response, error } = useAsync(editReportLoader, {
    reportId: reportId || "",
  });

  async function handleSubmit(
    report: GameReportFormSchema,
    publish: boolean = false
  ) {
    setIsSaving(true);

    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      await apolloApi.saveReport(upsertParams);

      if (publish) {
        toastSuccess("Report Published");
      } else {
        toastInfo("Report Saved");
      }
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toastError("Please log back in to save.");
        return navigateLogin();
      }
      toastError("Unable to save report. Please try again.");
    } finally {
      setIsSaving(false);
    }
  }

  if (error) {
    throw new SunsApiError("Error loading the report.", { cause: error });
  }

  if (loading) {
    return <ReportEditLoading />;
  }

  const { report, game, homeTeam, awayTeam } = response;

  if (!homeTeam || !awayTeam || !account.info) {
    throw new Error("Error loading report data");
  }

  const playerTeam =
    report.player.currentTeamId === homeTeam.id ? homeTeam : awayTeam;

  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Edit Report</Text>
      </Flex>
      <Card className="mb-4 overflow-hidden">
        <GameHero
          leagueId={game.leagueId}
          gameStatus={game.gameStatus || 1}
          gameTimeUTC={game.gameTimeUTC}
          gameClock={game.gameClock}
          period={game.period}
          homeTeam={{
            id: game.homeTeam?.id,
            image: game.homeTeam.image,
            tricode: game.homeTeam?.tricode,
            score: game.homeTeam!.score!,
          }}
          awayTeam={{
            id: game.awayTeam?.id,
            image: game.awayTeam.image,
            tricode: game.awayTeam?.tricode,
            score: game.awayTeam?.score,
          }}
        />
      </Card>
      <Card>
        <Flex direction="down" align="center" className="w-full">
          <Flex direction="down" className="max-w-4xl" gap="lg">
            <PlayerHeader
              {...report.player}
              playerImage={report.player.image}
              teamNbaId={playerTeam.nbaId}
              teamName={playerTeam.name}
              teamImage={playerTeam.image}
              leagueId={
                report.player.level === PlayerRow.level.PRO
                  ? LeagueId.NBA
                  : undefined
              }
            />
            <GameReportForm
              report={report}
              onSubmit={handleSubmit}
              isSaving={isSaving}
              player={report.player}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              author={account.info}
              gameVendor={report.gameVendor}
              gameVendorId={report.gameVendorId}
            />
          </Flex>
        </Flex>
      </Card>
    </>
  );
}

function ReportEditLoading() {
  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Edit Report</Text>
      </Flex>
      <Card className="mb-4">
        <GameHeroSkeleton />
      </Card>
      <Card className="grid grid-cols-4 space-x-8">
        <Flex direction="down">
          <SkeletonText rows={5} />
        </Flex>
        <PlayerHeaderSkeleton />
      </Card>
    </>
  );
}
