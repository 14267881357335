import { apolloApi } from "@/shared/api";
import { LeagueIds } from "@/shared/const";
import { useAsync } from "@/shared/hooks";

export function useLeagues() {
  const { loading, response, error, refresh } = useAsync(async () => {
    const data = await apolloApi.getLeagues({});
    return data.leagues.filter((league) =>
      [LeagueIds.NBA, LeagueIds.NCCA].includes(league.id)
    );
  });

  return { leagues: response, loading, error, refresh };
}
