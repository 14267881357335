import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function allIntelLoader({
  offset = 0,
  limit = 20,
}: {
  offset: number;
  limit: number;
}) {
  const response = await apolloApi.getIntel({
    offset,
    limit,
    status: IntelQueryParams.status.PUBLISHED,
  });
  return response;
}
