import { Suspense } from "react";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import ErrorFallback from "@/components/error-fallback/error-fallback";
import { RouteObject } from "react-router-dom";
import {
  ReportsCreate,
  ReportsListings,
  CreateGameReport,
  ReportEdit,
} from "@/pages/reports/index";
import { AllReportsListings } from "@/pages/reports/reports-listings/all-reports-listings";
import { ReportView } from "@/pages/reports/report-view/report-view";

export const ReportRoutes = {
  path: "reports",
  children: [
    {
      path: "",
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <ReportsListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "all-reports",
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <AllReportsListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <ReportsCreate />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create/game/:gameId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <CreateGameReport />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "edit/:reportId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <ReportEdit />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "view/:reportId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <ReportView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
  ],
} satisfies RouteObject;

export default ReportRoutes;
