import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Grid,
  Container,
  Select,
  SelectOption,
  SkeletonHeading,
  Skeleton,
  Flex,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks";
import { loader } from "./game-dna-listings-loader";
import { GameCard, GameHero, GameHeroSkeleton } from "./components";
import { Suns } from "@/shared/const";

export function GameDnaListings() {
  const { loading, response, error } = useAsync(loader, Suns.id);
  const [selectedTeamId, setSelectedTeamId] = useState<string>("all");
  const [selectedTimeframe, setSelectedTimeframe] = useState<
    "upcoming" | "past"
  >("past");
  const filteredGames = useMemo(() => {
    if (!response) {
      return [];
    }
    let games =
      selectedTimeframe == "upcoming"
        ? response.games.upcomingGames
        : response.games.pastGames;

    if (selectedTeamId != "all") {
      games = games.filter((game) => {
        const homeTeamId = game.homeTeamId;
        const awayTeamId = game.awayTeamId;
        return (
          `${homeTeamId}` == selectedTeamId || `${awayTeamId}` == selectedTeamId
        );
      });
    }

    return games.sort((a, b) => {
      const dateA = new Date(a.gameDateTimeUtc);
      const dateB = new Date(b.gameDateTimeUtc);
      return selectedTimeframe === "upcoming"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });
  }, [response, selectedTeamId, selectedTimeframe]);

  if (loading) {
    return <GameDnaListingsSkeleton />;
  }

  if (error) {
    throw Error("Error loading page", { cause: error });
  }

  const { teams, games } = response!;
  const { nextGame } = games;
  return (
    <>
      {nextGame ? (
        <Container size="lg">
          <Link to={`/game/${nextGame.gameId}`}>
            <Card
              badge="Next game"
              badgeProps={{ variant: "secondary" }}
              className="my-2"
            >
              <GameHero {...nextGame} />
            </Card>
          </Link>
        </Container>
      ) : null}
      {filteredGames ? (
        <Container size="lg">
          <Card>
            <Flex gap="sm" className="mb-6" wrap>
              <Select
                className="w-full md:w-32"
                value={selectedTimeframe}
                onValueChange={(value) =>
                  setSelectedTimeframe(value as "past" | "upcoming")
                }
              >
                <SelectOption value="past">Past games</SelectOption>
                <SelectOption value="upcoming">Upcoming games</SelectOption>
              </Select>
              <Select
                className="w-full md:w-48"
                value={selectedTeamId}
                onValueChange={setSelectedTeamId}
                placeholder="Select a team..."
              >
                <SelectOption value="all">All teams</SelectOption>
                {teams.map(({ id, name }) => (
                  <SelectOption key={`option-${id}`} value={id}>
                    {name}
                  </SelectOption>
                ))}
              </Select>
            </Flex>
            {filteredGames.length == 0 ? (
              <Text muted size="lg">
                No games found
              </Text>
            ) : (
              <Grid columns={["sm:2", "lg:3"]} gap="lg">
                {filteredGames.map((game) => (
                  <GameCard key={`game-${game.gameId}`} {...game} />
                ))}
              </Grid>
            )}
          </Card>
        </Container>
      ) : null}
    </>
  );
}

function GameDnaListingsSkeleton() {
  return (
    <>
      <Container size="lg">
        <Card
          badge="Next game"
          badgeProps={{ muted: true }}
          className="my-2 hover:bg-gray-50"
        >
          <GameHeroSkeleton />
        </Card>
      </Container>
      <Container size="lg">
        <Card header={<SkeletonHeading />}>
          <Grid columns="sm:2" gap="md">
            {[...Array(4).keys()].map((i) => (
              <Skeleton key={`sk-${i}`} className="h-[40px] w-full" />
            ))}
          </Grid>
        </Card>
      </Container>
    </>
  );
}
