import { apolloApi, getRollingSchedule } from "@/shared/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { GameRow, LeagueRow } from "@/swagger";
import { useEffect, useState } from "react";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Game {
  gameId: string;
  gameTime: string;
  homeTeamId: number;
  homeTeam: string;
  homeTeamImage?: string;
  awayTeamId: number;
  awayTeam: string;
  awayTeamImage?: string;
  arena: string;
  leagueId: string;
}

export function useReportSelect(date: Date) {
  const [selectedLeague, setSelectedLeague] = useState<LeagueRow>();
  const { fetchLeagues, leagues } = useLeagues();
  const { fetchGames, games } = useGames({
    date,
    leagueId: selectedLeague?.id,
    leagueName: selectedLeague?.name,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    fetchLeagues().catch((error) => setError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!leagues) return;
    setSelectedLeague(leagues[0]);
  }, [leagues]);

  useEffect(() => {
    if (!selectedLeague) return;
    setLoading(true);
    fetchGames()
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeague, date]);

  return {
    leagues,
    selectedLeague,
    setSelectedLeague,
    games,
    loading,
    error,
  };
}

function useLeagues() {
  const [leagues, setLeagues] = useState<LeagueRow[]>([]);

  const fetchLeagues = async () => {
    const response = await apolloApi.getLeagues({});
    setLeagues(response.leagues);
  };

  return { fetchLeagues, leagues };
}

function useGames(params: {
  date: Date;
  leagueId?: number;
  leagueName?: string;
}) {
  const [games, setGames] = useState<Game[]>([]);

  const fetchGames = async () => {
    if (!params.leagueId) return;
    if (!params.leagueName) return;

    if (params.leagueName === "NBA") {
      setGames(await fetchNbaGames(params.date.toISOString()));
    } else {
      setGames(await fetchApolloGames(params.date, params.leagueId));
    }
  };

  return { fetchGames, games };
}

async function fetchNbaGames(date: string) {
  const response = await getRollingSchedule({
    leagueId: "00",
    date: date,
  });
  const formateDate = (date: string) => {
    return dayjs(date).format("MM/DD/YYYY");
  };

  const schedule = response.gameDates?.filter((game) => {
    return formateDate(game?.gameDate || "") === formateDate(date);
  });

  const games = schedule?.[0]?.games
    ?.map((game) => {
      const gameTime = dayjs(game.gameDateTimeUTC)
        .tz(dayjs.tz.guess() ?? "America/New_York")
        .format("h:mma");

      const gameInfo = {
        gameId: game.gameId,
        homeTeamId: game.homeTeam?.teamId,
        homeTeam: game.homeTeam?.teamTricode,
        awayTeamId: game.visitorTeam?.teamId,
        awayTeam: game.visitorTeam?.teamTricode,
        gameTime,
        arena: game.arenaName,
        leagueId: "00",
      };
      return gameInfo;
    })
    .filter((game) => game !== undefined)
    .sort((a, b) =>
      dayjs(a.gameTime, "h:mma").diff(dayjs(b.gameTime, "h:mma"))
    );

  return games as Game[];
}

async function fetchApolloGames(date: Date, leagueId: number) {
  const response = await apolloApi.getGames({
    leagueId: leagueId,
    gameDate: date.toUTCString(),
  });
  const games = response.games
    ?.map((game: GameRow) => {
      const gameTime = dayjs(game.gameTimeUTC)
        .tz(dayjs.tz.guess() ?? "America/New_York")
        .format("h:mma");

      const gameInfo = {
        gameId: game.id.toString(),
        homeTeamId: game.homeTeamId,
        homeTeam: game.homeTeam?.code,
        homeTeamImage: game.homeTeam?.image,
        awayTeamId: game.awayTeamId,
        awayTeam: game.awayTeam?.code,
        awayTeamImage: game.awayTeam?.image,
        gameTime: gameTime,
        arena: game.arenaName,
      };
      return gameInfo;
    })
    .sort((a, b) =>
      dayjs(a.gameTime, "h:mma").diff(dayjs(b.gameTime, "h:mma"))
    );

  return games as Game[];
}
