import { Card, Flex, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";

import GameSelection from "./components/GameSelection";
import ReportTypeSelection from "./components/ReportTypeSelection";
import LeagueSelect from "./components/LeagueSelect";
import { DatePicker } from "@suns/design-system/src/components/DatePicker/DatePicker";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Game, useReportSelect } from "./hooks/useReportSelect";
import { SunsApiError } from "@suns/api";

export function ReportsCreate() {
  const [date, setDate] = useState<Date>(new Date());
  const [selectedReportType, setSelectedReportType] = useState<string>("Game");
  const [selectedGame, setSelectedGame] = useState<Game>();
  const { leagues, selectedLeague, setSelectedLeague, games, loading, error } =
    useReportSelect(date);

  const navigate = useNavigate();

  const handleReportTypeChange = (reportType: string) => {
    setSelectedReportType(reportType);
  };

  const handleSelectGame = (game: Game) => {
    setSelectedGame(game);
    navigate(`game/${game.gameId}`);
  };

  if (error) {
    throw new SunsApiError("Unable to load games.", {
      cause: error,
    });
  }

  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs" variant="muted">
          Report
        </Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs" variant="muted">
          Create Report
        </Text>
      </Flex>
      <Text heading className="mb-4 text-gray-600">
        Select Report Type
      </Text>
      <ReportTypeSelection
        handleReportTypeChange={handleReportTypeChange}
        selectedReportType={selectedReportType}
      />
      <Text heading className="mb-4 text-gray-600">
        Choose Game
      </Text>
      <Card className="h-full">
        <Flex gap="md">
          {selectedReportType === "Game" ? (
            <DatePicker date={date} setDate={setDate} />
          ) : null}
          <LeagueSelect
            leagueList={
              leagues?.filter(
                (league) => league.name === "NBA" || league.name === "NCAA D-I"
              ) || []
            }
            selectedLeague={selectedLeague}
            setSelectedLeague={setSelectedLeague}
          />
        </Flex>
        {selectedReportType === "Game" ? (
          <GameSelection
            selectedGame={selectedGame}
            handleSelectGame={handleSelectGame}
            games={games}
            loading={loading}
          />
        ) : null}
      </Card>
    </>
  );
}
