import React from "react";
import { CheckIcon, PlusIcon } from "@suns/design-system/icons";
import { Badge, Button, cn, Text } from "@suns/design-system";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@suns/design-system/src/components/Popover/Popover";
import { Separator } from "@suns/design-system/src/components/Separator/Separator";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@suns/design-system/src/components/Command/Command";

interface Option {
  key: string;
  name: string;
  PlayerLevel: string;
}

interface FormTagsProps {
  value: string[];
  onChange: (tags: string[]) => void;
  options: Option[];
}

export const FormTags = React.forwardRef<HTMLButtonElement, FormTagsProps>(
  ({ value = [], onChange, options }, ref) => {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="my-3" ref={ref}>
            <PlusIcon className="mr-2 size-4" />
            <Text size="sm">Add Tags</Text>
            {value?.length > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />

                <div className="space-x-1 lg:hidden">
                  <Badge
                    variant="primary"
                    className="rounded-md px-2 font-normal"
                  >
                    {value.length} selected
                  </Badge>
                </div>
                <div className="space-x-1 xs:hidden lg:flex">
                  {options
                    .filter((option) => value?.includes(option.key))
                    .map((option) => (
                      <Badge
                        variant="primary"
                        key={option.key}
                        className="rounded-md px-2 font-normal "
                      >
                        {option.name}
                      </Badge>
                    ))}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0" align="start">
          <Command>
            <CommandInput placeholder="Search" />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = value?.includes(option.key);
                  return (
                    <CommandItem
                      key={option.key}
                      onSelect={() => {
                        if (isSelected) {
                          onChange(value.filter((val) => val !== option.key));
                        } else {
                          onChange([...value, option.key]);
                        }
                      }}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-black",
                          isSelected
                            ? "bg-primary text-primary-foreground"
                            : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <CheckIcon />
                      </div>
                      <span>{option.name}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              {value?.length > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => onChange([])}
                      className="justify-center text-center"
                    >
                      Clear tags
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
