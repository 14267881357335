import { useAccount } from "@/shared/hooks/useAccount";
import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { myReportsLoader } from "./loaders/my-reports-loader";
import ReportListItem from "./components/ReportListItem";
import { Separator } from "@suns/design-system/src/components/Separator/Separator";
import {
  ReportQueryParams,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { apolloApi } from "@/shared/api";
import { useState } from "react";
import { PaginationControl } from "@/components";
import { SunsApiError } from "@suns/api";

const PAGE_SIZE = 20;

export function ReportsListings() {
  const account = useAccount();
  const [activeTab, setActiveTab] = useState("published");
  const [publishedPage, setPublishedPage] = useState(0);
  const [unPublishedPage, setUnpublishedPage] = useState(0);

  const {
    loading: publishedLoading,
    response: publishedReponse,
    refresh: refreshPublished,
    error: publishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username,
    status: ReportQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  const {
    loading: unPublishedLoading,
    response: unPublishedReponse,
    refresh: refreshUnPublished,
    error: unPublishedError,
  } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username,
    status: ReportQueryParams.status.UNPUBLISHED,
    offset: unPublishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  async function handleUnpublish(report: ReportResponseItem) {
    await apolloApi.saveReport({
      id: report.id,
      status: ReportResponseItem.status.UNPUBLISHED,
    });
  }

  if (publishedError) {
    throw new SunsApiError("Error loading the published reports.", {
      cause: publishedError,
    });
  }

  if (unPublishedError) {
    throw new SunsApiError("Error loading the unpublished reports.", {
      cause: unPublishedError,
    });
  }

  if (publishedLoading || unPublishedLoading) {
    return <ReportsListingsLoading />;
  }

  const currentPublishedPage = Math.floor(
    publishedReponse.offset / publishedReponse.limit
  );
  if (publishedPage !== currentPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshPublished();
  }

  const currentUnPublishedPage = Math.floor(
    unPublishedReponse.offset / unPublishedReponse.limit
  );
  if (unPublishedPage !== currentUnPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshUnPublished();
  }

  return (
    <Card
      header={
        <Text size="xl" heading>
          My Reports
        </Text>
      }
    >
      <Tabs onValueChange={setActiveTab} value={activeTab}>
        <TabsList className="mb-6">
          <TabsTrigger value="published">
            <Text>Published</Text>
          </TabsTrigger>
          <TabsTrigger value="unpublished">
            <Text>Unpublished</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="published">
          {publishedReponse.reports.length > 0 ? (
            publishedReponse.reports.map((report) => (
              <Flex direction="down" key={report.id}>
                <ReportListItem report={report} onUnpublish={handleUnpublish} />
                <Separator />
              </Flex>
            ))
          ) : (
            <Text>You don't have any published reports.</Text>
          )}
          <PaginationControl
            {...publishedReponse}
            onPageClicked={(page) => {
              setPublishedPage(page - 1);
            }}
          />
        </TabsContent>
        <TabsContent value="unpublished">
          {unPublishedReponse.reports.length > 0 ? (
            unPublishedReponse.reports.map((report) => (
              <Flex direction="down" key={report.id}>
                <ReportListItem report={report} onUnpublish={handleUnpublish} />
                <Separator />
              </Flex>
            ))
          ) : (
            <Text>You don't have any unpublished reports.</Text>
          )}
          <PaginationControl
            {...unPublishedReponse}
            onPageClicked={(page) => {
              setUnpublishedPage(page - 1);
            }}
          />
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function ReportsListingsLoading() {
  return (
    <Card
      header={
        <Text size="xl" heading>
          My Reports
        </Text>
      }
    >
      <Flex direction="down" gap="lg">
        <SkeletonText />
        <SkeletonText />
      </Flex>
    </Card>
  );
}
