import { apolloApi } from "@/shared/api";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";

export async function myReportsLoader({
  authorUsername,
  status,
  offset = 0,
  limit = 20,
}: {
  authorUsername?: string;
  status:
    | ReportQueryParams.status.PUBLISHED
    | ReportQueryParams.status.UNPUBLISHED;
  offset: number;
  limit: number;
}) {
  const response = await apolloApi.getReports({
    offset,
    limit,
    status,
    authorUsername: authorUsername ?? "",
  });

  return response;
}
