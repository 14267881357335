import {
  Flex,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Checkbox,
} from "@suns/design-system";

interface FormCheckboxOption {
  value: string;
  label: string;
}

interface FormCheckboxGroupParams {
  name: string;
  options: readonly FormCheckboxOption[];
  multiselect: boolean;
}

export function FormCheckboxGroup({
  name,
  options,
  multiselect = false,
}: FormCheckboxGroupParams) {
  return (
    <FormField
      name={name}
      render={() => (
        <FormItem>
          <Flex direction="right" gap="md" wrap>
            {options.map((option) => (
              <FormField
                key={option.value}
                name={name}
                render={({ field }) => (
                  <FormItem>
                    <Flex direction="right" gap="sm" align="center">
                      <FormControl>
                        <Checkbox
                          className="border-muted-900 data-[state=checked]:bg-muted-900"
                          checked={
                            multiselect
                              ? field.value?.includes(option.value)
                              : field.value === option.value
                          }
                          onCheckedChange={(checked) => {
                            if (multiselect) {
                              return checked
                                ? field.onChange([
                                    ...(field.value ?? []),
                                    option.value,
                                  ])
                                : field.onChange(
                                    field.value?.filter(
                                      (value: string) => value !== option.value
                                    )
                                  );
                            } else {
                              return field.onChange(option.value);
                            }
                          }}
                        />
                      </FormControl>
                      <FormLabel>{option.label}</FormLabel>
                    </Flex>
                  </FormItem>
                )}
              />
            ))}
          </Flex>
        </FormItem>
      )}
    />
  );
}
